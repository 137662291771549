import fetch from 'isomorphic-unfetch'
import queryString from 'query-string'
import { SortIcon } from './../Shape'
import _ from 'lodash'
import style from './style'

const bibSelect = [
	{value: '', name: 'select a bibliographic code'},
  {value: 'A', name: 'A: BY ASHBERY: full books, translations'},
	{value: 'A2', name: 'A2: BY ASHBERY: broadsides, chapbooks'},
	{value: 'B', name: 'B: BY ASHBERY:  books, programs with Ashbery contributions'},
	{value: 'C', name: 'C: BY ASHBERY: pieces in periodicals/newspapers by or translated by'},
	{value: 'D', name: 'D: BY ASHBERY: books, periodicals, series edited or with works selected by'},
	{value: 'E', name: 'E: BY ASHBERY: art writing by or translated by in books, catalogs, announcements'},
	{value: 'F', name: 'F: BY ASHBERY: art writing by or translated by in periodicals, newspapers'},
	{value: 'G', name: 'G: BY ASHBERY: translations of works by'},
	{value: 'H', name: 'H: BY ASHBERY: interviews with'},
	{value: 'J1', name: 'J1: BY ASHBERY: misc. writings, published remarks, brief published correspondence'},
	{value: 'J2', name: 'J2: BY ASHBERY: blurbs by A'},
	{value: 'J3', name: 'J3: BY ASHBERY: Audio or video recordings of'},
	{value: 'J4', name: 'J4: BY ASHBERY: Visual artwork created by'},
	{value: 'J5', name: 'J5: BY ASHBERY: performances in plays and films by A'},
	{value: 'K1', name: 'K1: ABOUT ASHBERY: cinema, including films based on, influenced by, related to'},
	{value: 'K2', name: 'K2: ABOUT ASHBERY: literature, including creative writing based on, influenced by, related to'},
	{value: 'K3', name: 'K3: ABOUT ASHBERY: music compositions based on, influenced by, related to'},
	{value: 'K4', name: 'K4: ABOUT ASHBERY: plays, opera, dance based on, influenced by, related to'},
	{value: 'K5', name: 'K5: ABOUT ASHBERY: visual art based on, influenced by, related to'},
	{value: 'K6', name: 'K6: ABOUT ASHBERY: marketing/promotional materials related to'},
	{value: 'L1', name: 'L1: ABOUT ASHBERY: discussions of in general; in books, dissertations, journals, lectures'},
	{value: 'L2', name: 'L2: ABOUT ASHBERY: book reviews.discussions  of collections by'},
	{value: 'L3', name: 'L3: ABOUT ASHBERY: selections of by others in competitions or compilations'},
	{value: 'L4', name: 'L4: ABOUT ASHBERY: pedagogical applications for work by, including teaching materials on'},
	{value: 'M', name: 'M: ABOUT ASHBERY: anthologies containing previously published works or translations by'},
	{value: 'N1', name: 'N1: ABOUT ASHBERY: miscellaneous passing mentions of in print'},
	{value: 'N2', name: 'N2: ABOUT ASHBERY: miscellaneous biographical information on life of and family history'},
	{value: 'N3', name: 'N3: ABOUT ASHBERY: contextual information that relates to topics on'},
	{value: 'O', name: 'O: ABOUT ASHBERY: Of interest to'},
	{value: 'P', name: 'P: ABOUT ASHBERY: information about “created spaces” of'},
	{value: 'R', name: 'R: ABOUT ASHBERY: Influential on A and/or for a specific A work'},
	{value: 'Q', name: 'Q: ASHBERY’S COLLECTION: object or printed matter owned by'},
	{value: 'Z', name: 'Z: THE FLOW CHART FOUNDATION: materials from or related to'}
];

const formatSelect = [
	{value: 'Art publication'},
	{value: 'Artist Book'},
	{value: 'Audio recording'},
	{value: 'Book'},
	{value: 'Broadside'},
	{value: 'Chapbook'},
	{value: 'Miscellaneous'},
	{value: 'Periodical'},
	{value: 'Program'},
	{value: 'Script'},
	{value: 'Sheet music'},
	{value: 'Unpublished'},
	{value: 'Video recording'},
	{value: 'Website'}
];

const typeSelect = [
	{value: 'Blurb'},
	{value: 'Competition judged by Ashbery'},
	{value: 'Correspondence'},
	{value: 'Dissertation/Thesis'},
	{value: 'Edited/selected by Ashbery'},
	{value: 'Fiction'},
	{value: 'Film'},
	{value: 'Interview'},
	{value: 'Miscellaneous object'},
	{value: 'Miscellaneous prose'},
	{value: 'Miscellaneous published remark'},
	{value: 'Music'},
	{value: 'Performance'},
	{value: 'Play'},
	{value: 'Poetry'},
	{value: 'Reading'},
	{value: 'Review'},
	{value: 'Translation'},
	{value: 'Visual art'},
	{value: 'Writing about art'}
];

const langSelect = [
	{value: "Arabic"},
	{value: "Bengali"},
	{value: "Catalan"},
	{value: "Chinese"},
	{value: "Czech"},
	{value: "Danish"},
	{value: "Dutch"},
	{value: "English"},
	{value: "Finnish"},
	{value: "French"},
	{value: "German"},
	{value: "Greek"},
	{value: "Hindi"},
	{value: "Hungarian"},
	{value: "Italian"},
	{value: "Japanese"},
	{value: "Norwegian"},
	{value: "Polish"},
	{value: "Portuguese"},
	{value: "Romanian"},
	{value: "Russian"},
	{value: "Serbo-Croatian"},
	{value: "Slovak"},
	{value: "Slovenian"},
	{value: "Spanish"},
	{value: "Swedish"},
	{value: "Turkic"},
	{value: "Vietnamese"}
];

const SearchInput = (props) => (
  <div
    className='search-form__field-group'
    rel={props.fieldKey}
    key={props.fieldKey}>
    {props.isAdvanced
      && (<span className='search-form__field-ordinal'>{props.index + 1}</span>)
    }
    {(props.isAdvanced &&(props.fieldKey !='publicationDate2'))
      && (<select
        className='search-form__select'
        name='operant'
        value={props.query[props.fieldKey] ? props.query[props.fieldKey]['operand'] : '$and'}
        onChange={props.setInputQueryToState}>
        <option value='$or'>OR</option>
        <option value='$and'>AND</option>
      </select>)
    }
	{(props.isAdvanced &&(props.fieldKey =='publicationDate2'))
      && (<select
        className='search-form__select'
        name='operant'
        value={props.query[props.fieldKey] ? props.query[props.fieldKey]['operand'] : '$and'}
        onChange={props.setInputQueryToState}>
        <option value='$or'>OR</option>
        <option value='$and'>AND</option>
      </select>)
    }
    {props.fieldKey =='publicationDate' && (
		<label className='search-form__input-label'>Start Date Range</label>
	)}
	{props.fieldKey =='publicationDate2' && (
		<label className='search-form__input-label'>End Date Range</label>
	)}
    {props.isAdvanced && (props.fieldKey !='publicationDate') && (props.fieldKey !='publicationDate2')
      && (<label className='search-form__input-label'>{_.capitalize(props.fieldKey.replace(/([A-Z])/g, " $1"))}</label>)
    }
    {props.fieldKey =='bibliographicCode' && (
	  	<select
	        className='search-form__input'
	        name='value'
	        value={props.query[props.fieldKey] ? props.query[props.fieldKey]['bibliographicCode'] : ''}
	        onChange={props.setInputQueryToState}>
			{bibSelect.map((item, key) =>
			    <option value={item.value}>{item.name}</option>
			)}
	      </select>
	)}
	{props.fieldKey =='format' && (
	  	<select
	        className='search-form__input'
	        name='value'
	        value={props.query[props.fieldKey] ? props.query[props.fieldKey]['format'] : ''}
	        onChange={props.setInputQueryToState}>
				<option value =''>select a format</option>
			{formatSelect.map((item, key) =>
			    <option value={item.value}>{item.value}</option>
			)}
	      </select>
	)}
	{props.fieldKey =='type' && (
	  	<select
	        className='search-form__input'
	        name='value'
	        value={props.query[props.fieldKey] ? props.query[props.fieldKey]['type'] : ''}
	        onChange={props.setInputQueryToState}>
				<option value =''>select a type</option>
			{typeSelect.map((item, key) =>
			    <option value={item.value}>{item.value}</option>
			)}
	      </select>
	)}
	{props.fieldKey =='language' && (
	  	<select
	        className='search-form__input'
	        name='value'
	        value={props.query[props.fieldKey] ? props.query[props.fieldKey]['language'] : ''}
	        onChange={props.setInputQueryToState}>
				<option value =''>select a language</option>
			{langSelect.map((item, key) =>
			    <option value={item.value}>{item.value}</option>
			)}
	      </select>
	)}
	{props.fieldKey =='publicationDate' && (
	  	<input
	      className='search-form__input'
	      type='text'
	      name='value'
			placeholder='yyyy-mm-dd'
	      value={props.query[props.fieldKey] ? props.query[props.fieldKey]['value'] : ''}
	      onChange={props.setInputQueryToState} />
	)}
	{props.fieldKey =='publicationDate2' && (
	  	<input
	      className='search-form__input'
	      type='text'
	      name='value'
			placeholder='yyyy-mm-dd'
	      value={props.query[props.fieldKey] ? props.query[props.fieldKey]['value'] : ''}
	      onChange={props.setInputQueryToState} />
	)}
	{((props.fieldKey !='bibliographicCode')&&(props.fieldKey !='format')&&(props.fieldKey !='type')&&(props.fieldKey !='language')&&(props.fieldKey !='publicationDate')&&(props.fieldKey !='publicationDate2')) && (
    <input
      className='search-form__input'
      type='text'
      name='value'
      value={props.query[props.fieldKey] ? props.query[props.fieldKey]['value'] : ''}
      onChange={props.setInputQueryToState} />
	)}
  </div>
)

const SearchTypeSelector = (props) => (
  <select
    name='searchType'
    className='search-form__select'
    value={props.isAdvanced ? 'advanced' : 'basic'}
    onChange={(e) => props.controlQueryType(e)}>
    <option className='search-form__option' value='basic'>Basic Search</option>
    <option className='search-form__option' value='advanced'>Advanced Search</option>
  </select>
)

const SearchPrecisionSelector = (props) => (
  <select
    name='searchPrecision'
    className='search-form__select'
    value={props.isAny ? 'any' : 'exact'}
    onChange={(e) => props.controlQueryPrecision(e)}>
    <option className='search-form__option' value='any'>Any Phrase</option>
    <option className='search-form__option' value='exact'>Exact Phrase</option>
  </select>
)

const SearchControl = (props) => (
  <label htmlFor='clearSubmit'>
    <input
      className='search-form__submit' type='submit' value='SEARCH' />
    <input
      className='search-form__submit'
      type='submit'
      value='Clear'
      onClick={props.clearQuery} />
  </label>
)

const SearchForm = (props) => (
  <form
    className='search-form'
    onSubmit={(e) => props.setSearchResultsToState(e)}>
    <SearchTypeSelector
      isAdvanced={props.isAdvanced}
      controlQueryType={props.controlQueryType} />
    {props.isAdvanced
      ? props.inputList.map((fieldKey, index) =>
        <SearchInput
          fieldKey={fieldKey}
          index={index}
          query={props.query}
          setInputQueryToState={props.setInputQueryToState}
          isAdvanced={props.isAdvanced} />)
      : (<SearchInput
          setInputQueryToState={props.setInputQueryToState}
          fieldKey='basic'
          query={props.query}
          setInputQueryToState={props.setInputQueryToState}
          isAdvanced={props.isAdvanced} />)
    }
    {props.isAdvanced && (
      <SearchPrecisionSelector
        isAny={props.isAny}
        controlQueryPrecision={props.controlQueryPrecision} />)
    }
    <SearchControl clearQuery={props.clearQuery} />
  </form>
)

class CatSearchResult extends React.PureComponent {
  render () {
    const highlightedResultFieldValue = (fieldKey, fieldValue) => {
      let highlightedFieldValue

      if (this.props.query[fieldKey]) {
        const regEXP = new RegExp(this.props.query[fieldKey]['value'].split(' ').map(word => `(${word})`).join('|'), 'gi')
        highlightedFieldValue = fieldValue.replace(regEXP, '<span style=\'background-color: lightgreen\'>$&</span>')
      } else if ('basic' in this.props.query) {
        const regEXP = new RegExp(this.props.query['basic']['value'].split(' ').map(word => `(${word})`).join('|'), 'gi')
        highlightedFieldValue = fieldValue.replace(regEXP, '<span style=\'background-color: lightgreen\'>$&</span>')
      } else {
        highlightedFieldValue = fieldValue
      }

      return highlightedFieldValue
    }

    return (
      <div
        rel={this.props.result._id}
        key={this.props.result._id}
        className='search-result'>
        {this.props.inputList.map((field, idx) => {
			var ff = this.props.result[field]
			ff = ff + ''
			var fff = ff.replace(/(\d{4})(\d{2})(\d{2})/,'$1-$2-$3')
			if (field=='publicationDate') {return(
				<div
	                key={idx}
	                className='search-result__field'>
	                <div className='search-result__field-key'>
	                  <div
	                    className='search-result__field-sort'
	                    onClick={(e) => this.props.toggleSortField(e, field)}>
	                    <SortIcon sortDir={
	                      this.props.sort[field] === -1
	                        ? 'asc'
	                        : 'desc'
	                    } />
	                  </div>
	                  {field.replace(/([A-Z])/g, " $1").toUpperCase()}
	                </div>
	                <div
	                  className='search-result__field-value'
	                  dangerouslySetInnerHTML={{ __html: highlightedResultFieldValue(field, fff) }}>
	                </div>
	              </div>
				)
			}
          else if (this.props.result[field]) {
            return (
              <div
                key={idx}
                className='search-result__field'>
                <div className='search-result__field-key'>
                  <div
                    className='search-result__field-sort'
                    onClick={(e) => this.props.toggleSortField(e, field)}>
                    <SortIcon sortDir={
                      this.props.sort[field] === -1
                        ? 'asc'
                        : 'desc'
                    } />
                  </div>
                  {field.replace(/([A-Z])/g, " $1").toUpperCase()}
                </div>
                <div
                  className='search-result__field-value'
                  dangerouslySetInnerHTML={{ __html: highlightedResultFieldValue(field, this.props.result[field]) }}>
                </div>
              </div>
            )
          }
        })}
      </div>
    )
  }
}


export default class CatSearch extends React.Component {
  constructor(props) {
    super(props)

    this.clearQuery = this.clearQuery.bind(this)
    this.toggleSortField = this.toggleSortField.bind(this)
    this.controlQueryType = this.controlQueryType.bind(this)
    this.controlQueryPrecision = this.controlQueryPrecision.bind(this)
    this.controlQueryPerPage = this.controlQueryPerPage.bind(this)
    this.controlQueryPage = this.controlQueryPage.bind(this)
    this.incrementQueryPage = this.incrementQueryPage.bind(this)
    this.goToPage = this.goToPage.bind(this)
    this.parameterizeSearch = this.parameterizeSearch.bind(this)
    this.setSearchResultsToState = this.setSearchResultsToState.bind(this)
    this.setInputQueryToState = this.setInputQueryToState.bind(this)
  }
  inputList = [
    'bibliographicCode',
    'author',
    'title',
    'publication',
    'editor',
    'translator',
    'language',
    'publicationPlace',
    'publisher',
    'publicationDate',
		'publicationDate2',
    'illustration',
		'link',
    'format',
    'type',
    'notes'
  ]

  state = {
    advanced: true,
    exact: false,
    isSearching: false,
    perPage: 10,
    page: 1,
    totalPages: null,
    query: {},
    sort: {
      bibliographicCode: 1,
      publicationDate: 1,
      author: 1,
      publisher: 1,
    },
    results: []
  }

  /**
   * clears query state to yield all results
   */
  clearQuery() {
    this.setState({
      query: {},
      page: 1
    })
  }

  /**
   * toggles sort field
   * @param {event} the React `SyntheticEvent`
   */
  toggleSortField(event, field) {
    this.setState((prevState) => ({
      sort: { [field]: prevState.sort[field] ? prevState.sort[field] * -1 : -1 }
    }), () => this.setSearchResultsToState())
  }

  /**
   * toggle between displaying of advanced search fields
   * and the basic search field
   * @param {event} the React `SyntheticEvent`
   */
  controlQueryType(event) {
    event.target.value === 'advanced'
      ? this.setState({advanced: true, query: {}})
      : this.setState({advanced: false, query: {}})
  }

  /**
   * toggle between exact phrase search
   * and any word search
   * @param {event} the React `SyntheticEvent`
   */
  controlQueryPrecision(event) {
    event.target.value === 'any'
      ? this.setState({any: true})
      : this.setState({any: false})
  }

  /**
   * change number of results per page
   * @param {event} the React `SyntheticEvent`
   */
  controlQueryPerPage(event) {
    const perPage = parseInt(event.target.value)

    this.setState((prevState) => ({
      perPage
    }), () => this.setSearchResultsToState())
  }

  /**
   * change page number according to input
   * @param {event} the React `SyntheticEvent`
   */
  controlQueryPage(event) {
    const page = parseInt(event.target.value)

    this.setState((prevState) => ({
      page
    }), () => this.setSearchResultsToState())
  }

  /**
   * increment/decrement page number according to input
   * @param {event} the React `SyntheticEvent`
   */
  incrementQueryPage(event) {
    const i = event.target.getAttribute('rel') === '-' ? -1 : 1

    window.scrollTo({
      top: 0,
      behavior: 'smooth'
    })

	if ('parentIFrame' in window) {
	  parentIFrame.scrollToOffset(0,0);
	}

    this.setState((prevState) => ({
      page: prevState.page + i
    }), () => this.setSearchResultsToState())
  }

  goToPage(pageNumber) {
    this.setState((prevState) => ({
      page: pageNumber,
    }), () => this.setSearchResultsToState())
  }

  /**
   * add or change corresponding entry in `this.state.query`,
   * including the search fields AND/OR operator
   * @param {event} the React `SyntheticEvent`
   */
  setInputQueryToState(event) {
    const key = event.target.parentElement.getAttribute('rel')
    const operand = event.target.parentElement.getElementsByTagName('select').length ? event.target.parentElement.getElementsByTagName('select')[0].value : ''
	var v = '';
	if((event.target.parentElement.getAttribute('rel')=='bibliographicCode')||(event.target.parentElement.getAttribute('rel')=='format')||(event.target.parentElement.getAttribute('rel')=='type')||(event.target.parentElement.getAttribute('rel')=='language')){
		v = event.target.parentElement.getElementsByTagName('select')[1].value
	}else{
		v = event.target.parentElement.getElementsByTagName('input')[0].value
	}
	const value = v

    this.setState((prevState) => {
      const newQuery = prevState.query
      newQuery[key] = {operand, value}

      return ({
        query: newQuery,
        page: 1,
      })
    })
  }

  /**
   * creates new object from `this.state.query`,
   * and returns the new object as a param string
   */
  parameterizeSearch(page = this.state.page) {
    let newQueryObject =
      _.uniq(Object.keys(this.state.query)
      .map(field => this.state.query[field]['operand']))
      .reduce((map, operand) => {
        if(this.state.advanced) {
          const operandArray = Object.keys(this.state.query)
            .filter(field => this.state.query[field]['operand'] === operand && this.state.query[field]['value'].trim())
            .map((field, index) => ({ [field]: this.state.query[field]['value'] }))

          if(operandArray.length) {
            if(map['advanced']) {
              map['advanced'][operand] = operandArray
            } else {
              map['advanced'] = { [operand]: operandArray }
            }
          }
        } else {
          map['basic'] = this.state.query.basic.value
        }

        return map
      }, {
        exact: this.state.exact,
        per_page: this.state.perPage,
        page: page,
        sort: this.state.sort
      })

    newQueryObject.sort = JSON.stringify(newQueryObject.sort)

    if(this.state.advanced) {
      newQueryObject.advanced = JSON.stringify(newQueryObject.advanced)
    }

    return queryString.stringify(newQueryObject)
  }

  /**
   * async call to endpoint & set result to `this.state.results`
   * @param {event} the React `SyntheticEvent` (optional)
   */
  async setSearchResultsToState(event) {
    event && event.preventDefault()
    const endpoint = 'https://data.ashberyresourcecenter.com/api/catalogue/list'
    const params = this.parameterizeSearch()

    this.setState({ isSearching: true })

    const req = await fetch(`${endpoint}?${params}`)
    const results = await req.json()

    this.setState((prevState) => ({
      totalPages: results.total_pages,
      results: results.catalogues
    }), () => this.setState({ isSearching: false }))
  }

  componentDidMount() {
    this.setSearchResultsToState()
  }

  render() {
    const searchPagesArray = () => {
      const page = this.state.page
      const totalPages = this.state.totalPages
      const length = 9
      const offset = page - ((length / 2) - 0.5)
      const offsetRange = Array.from({length}, (_, i) => i + offset)
      const startRange = Array.from({length}, (_, i) => i + 1)
      const shortRange = Array.from({length: totalPages}, (_, i) => i + 1)
      const endRange = Array.from({length}, (_, i) => i + (totalPages - length))

      return this.state.page < 5
        ? totalPages <= length
          ? shortRange
          : startRange
        : this.state.page < (totalPages - 5)
          ? offsetRange
          : endRange
    }

    return (
      <div className='search'>
        <SearchForm
          setSearchResultsToState={this.setSearchResultsToState}
          isAdvanced={this.state.advanced}
          isExact={this.state.exact}
					isAny={this.state.any}
          controlQueryType={this.controlQueryType}
          inputList={this.inputList}
          query={this.state.query}
          setInputQueryToState={this.setInputQueryToState}
          controlQueryPrecision={this.controlQueryPrecision}
          clearQuery={this.clearQuery} />

        <div className={`search-results ${this.state.isSearching ? 'is-searching' : 'is-notSearching'}`}>
          <div className='search-results__page-control'>
            <div className='search-results__per-page'>
              <label htmlFor='perPage'>
                Per page:
                <select
                  name='perPage'
                  className='search-form__select'
                  value={this.state.advanced.perPage}
                  onChange={(e) => this.controlQueryPerPage(e)}>
                    <option className='search-form__option' value='10'>10</option>
                    <option className='search-form__option' value='50'>50</option>
                    <option className='search-form__option' value='100'>100</option>
                </select>
              </label>
            </div>
            <div className='search-results__paginater'>
              <span className='search-results__paginater-title'>Page:</span>
              <ul className='search-results__paginater-links'>
                {searchPagesArray().filter(function(obj) {
					if(obj > 0){
				    	return obj
					}
				  }).map(pageNumber => (
                  pageNumber !== this.state.page
                    ? (<li className='search-results__paginater-link'>
                        <a onClick={() => this.goToPage(pageNumber)}>
                          {pageNumber}
                        </a>
                      </li>)
                    : (<li className='search-results__paginater-link'>{pageNumber}</li>)
                ))}
              </ul>
              <span>of&nbsp;</span>
              <span className='search-results__paginater-total'>
                {this.state.page !== this.state.totalPages
                  ? (<a onClick={() => this.goToPage(this.state.totalPages)}>
                     &nbsp;{this.state.totalPages}
                    </a>)
                  : (<span>&nbsp;{this.state.totalPages}</span>)
                }
              </span>
            </div>
            <div className='search-results__buttons'>
              {this.state.results.length && this.state.page !== this.state.totalPages
                ? (<button
                    className='search-results__button'
                    rel='+'
                    onClick={(e) => this.incrementQueryPage(e)}>
                    Next Page
                  </button>)
                : ''
              }
              {this.state.results.length && this.state.page !== 1
                ? (<button
                    className='search-results__button'
                    rel='-'
                    onClick={(e) => this.incrementQueryPage(e)}>
                    Prev Page
                  </button>)
                : ''
              }
            </div>
          </div>
         {this.state.results.length
            ? this.state.results.map((result,idx) => (
                <CatSearchResult
                  query={this.state.query}
                  result={result}
                  inputList={this.inputList}
                  toggleSortField={this.toggleSortField}
                  sort={this.state.sort} />
              ))
            : (<pre className='search-results__result'>No results</pre>)
          }
          <div className='search-results__per-page'>
            {this.state.results.length && !this.state.isSearching
              ? (<button
                    className='search-results__button'
                    rel='+'
                    onClick={(e) => this.incrementQueryPage(e)}>
                      Next Page
                  </button>)
              : ''
            }
            {this.state.results.length && !this.state.isSearching
              ? (<button
                    className='search-results__button'
                    rel='-'
                    onClick={(e) => this.incrementQueryPage(e)}>
                      Prev Page
                  </button>)
              : ''
            }
          </div>
        </div>
        <style jsx global>{style}</style>
      </div>
    )
  }
}
