import style from './style'
import Link from 'next/link'
import { SearchIcon } from './../Shape'

export default class SearchBar extends React.Component {
  state = {
    searchBarActive: false
  }
  
  queryData = (event) => {
    const filteredPages = this.props.pages.filter((page) => {
      const titleIncludes = page.title ? page.title.toUpperCase().includes(event.target.value.toUpperCase()) : ''
      const contentIncludes = page.content ? page.content.includes(event.target.value) : ''

      return titleIncludes || contentIncludes
    })

    this.setState({results: filteredPages})
  }

  handleInput = (event) => {
    if (event.defaultPrevented) {
      return 
    }

    switch(event.keyCode) {
      case 13: //enter
        this.setState({searchBarActive: true})
        this.queryData(event)
        break
      case 27: //escape
        this.setState({searchBarActive: false})
        break
      default:
        return
    }
  }

  render() {
    return (
      <div className={`search-bar ${this.state.searchBarActive ? 'is-active' : 'is-inactive'}`}>
        <div className='search-bar__field'>
          <span className='search-bar__icon'>
            <SearchIcon />
          </span>
          <input 
            className='search-bar__input' 
            type='search' 
            placeholder='search entire site'
            onKeyDown={(e) => this.handleInput(e)} />
        </div>
        <ul className='search-bar__results'>
          {this.state.results 
            ? this.state.results.map(result => (
                <li className='search-bar__result'>
                  <Link href={result.slug} as={result.slug}>
                    <a className='search-bar__result-link'>
                      {result.title}
                    </a>
                  </Link>
                </li>
              ))
            : ''
          }
        </ul>
        <style jsx global>{style}</style>
      </div>
    )
  }
}
