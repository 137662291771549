export const SearchIcon = (props) => (
  <svg viewBox="0 0 64 64">
    <path data-name="layer2" fill="none" strokeMiterlimit="10" strokeWidth="8" d="M38.192 38.191L50 50" strokeLinejoin="miter" strokeLinecap="butt" />
    <circle data-name="layer1" cx="29" cy="29" r="13" fill="none" strokeMiterlimit="10" strokeWidth="8" strokeLinejoin="miter" strokeLinecap="butt" />
  </svg>
)

export const SortIcon = (props) => (
  <svg viewBox="0 0 64 64">
    {props.sortDir === 'asc' && (
      <path fill="none" strokeMiterlimit="10" strokeWidth="4" d="M20 40l11.994-14L44 40" strokeLinejoin="miter" strokeLinecap="round" />
    )}

    {props.sortDir === 'desc' && (
      <path fill="none"  strokeMiterlimit="10" strokeWidth="4" d="M20 26l11.994 14L44 26" strokeLinejoin="miter" strokeLinecap="round" />
    )}
  </svg>
)
