import style from './style'

const SubSectionTOC = (props) => {
  const renderSectionTOC = (subSections) => {
    const tableOfContents = subSections.map(section => {
      let subItem

      if(section.subSections.length) subItem = renderSectionTOC(section.subSections)

      return (
        <li 
          onClick={() => document.getElementById(`section${section._id}`).scrollIntoView({
            block: 'start',
            behavior: 'smooth',
          })}
          className='section-toc__item'
          key={section._id}>
          <a>{section.title}</a>
          <ul className='section-toc section-toc--sub'>
            {subItem}
          </ul>
        </li>
      )
    })

    return tableOfContents
  }

  return props.currentPage.subSections && props.currentPage.subSections.length
    ? (<ul id='sectionTOC' className='section-toc'>{renderSectionTOC(props.currentPage.subSections)}<style jsx global>{style}</style></ul>)
    : ''
}

export default SubSectionTOC
