import css from 'styled-jsx/css'

const style = css.global`
  .header { 
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    align-items: center;
    padding: 5px 10px;
    margin-top: 0.3125rem;
    margin-right: 0.3125rem;
    margin-left: 0.375rem;
    background-color: #fcd1ca;
    margin-bottom: 0.75rem;
    box-shadow: 
      0 0 0 1px white,
      0 0 0 5px #fcd1ca,
      0 0 0 6px black;
  }

  .header__title {
    color: #3f389b;
    font-weight: 600; 
    display: flex;
    flex-direction: column;
    line-height: 1.375;
    font-size: 2vw;

    &:hover {
      color: #3f389b;
    }

    @media (min-width: 680px) {
      font-size: 1.1875rem;
    }
  }

  .header__title-welcome {
    font-size: 1.125em;
  }

  .header__title-arc {
    font-size: 1.625em;
    letter-spacing: 0.125em;
  }

  .header__title-project {
    letter-spacing: 0.125em;

    & em {
      font-style: italic;
    }
  }

  .header__graphic {
    width: 9%;
  }
`

export default style
