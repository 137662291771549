import css from 'styled-jsx/css'

const style = css.global`
  .search-bar {
    position: relative;
    background-color: #fcd1ca;
    border: 1px solid black;
    margin-bottom: 0.5rem;
    width: 100%;
    margin: 0 auto;

    @nest .page--home & {
      margin-top: 2rem;
    }
  }

  .search-bar::focus {
    background-color: #e0e0e0;
  }

  .search-bar__field {
    display: flex;
    width: 100%;
  }

  .search-bar__icon {
    width: 1.125rem;
    height: 1.125rem;
    margin-top: 0.375rem;
    margin-left: 0.25rem;
    stroke: #444;
    position: absolute;
  }

  .search-bar__input {
    font-family: 'Verdana', Arial, sans-serif;
    background-color: #fcd1ca;
    font-size: 0.875rem;
    padding: 0.375rem 0.375rem 0.375rem 1.375rem;
    border: none;
    box-shadow: none;
    outline: none;
    width: 100%;
    font-weight: bold;
    color: #666666;
    transition: background-color 150ms ease-in-out;
  }

  .search-bar__input:focus,
  .search-bar__input:hover {
    background-color: #e0e0e0;
  }

  .search-bar__input::placeholder {
    font-weight: bold;
    color: #666666;
  }

  .search-bar__results {
    position: absolute;
    display: flex;
    flex-direction: column;
    list-style: none;
    padding: 0 1.25rem;
    z-index: 999;
    background-color: #e0e0e0;
    width: calc(100% + 2px);
    margin-left: -1px;
    border-bottom: 1px solid black;
    border-right: 1px solid black;
    border-left: 1px solid black;
    overflow: scroll;
    transition: max-height 250ms ease-in-out;

    @nest .search-bar.is-active & {
      max-height: 405px;
    }

    @nest .search-bar.is-inactive & {
      max-height: 0;
    }
  }

  .search-bar__result {
    font-size: 0.875rem;
    margin-bottom: 0.625rem;

    &:first-child {
      margin-top: 0.5rem;
    }

    &:last-child {
      margin-bottom: 1.125rem;
    }
  }

  .search-bar__result-link {
    color: #666666;
    cursor: pointer;
  }

  .search-bar__result-link:hover {
    color: #666666;
  }
`

export default style
