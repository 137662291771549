import CatSearch from './../CatSearch'
import style from './style'

const Content = (props) => {
  if(props.currentPage === '404') {
    return (
      <div className='content'>
        404
        <style jsx>{style}</style>
      </div>
    )
  } else if (props.currentPage.slug === 'search-the-catalog') {
    return (
      <div className='content'>
        <div 
          className='content' 
          dangerouslySetInnerHTML={{__html: props.currentPage.content }} />
          <CatSearch />
          <style jsx>{style}</style>
      </div>
    ) 
  } else {
    return (
       <div 
          className='content'>
          <div className='content' dangerouslySetInnerHTML={{__html: props.currentPage.content }} />
          <style jsx global>{style}</style>
        </div>
    )
  }
} 

export default Content
