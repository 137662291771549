import css from 'styled-jsx/css'

const style = css.global`
  .main {
    overflow: hidden;

    @nest .page--home & {
      margin-top: 1rem;  
    }

    @nest .page--default & {
      margin-top: 2rem;
      background-color: #e0e0e0;
      margin-bottom: 2rem;
      padding: 1rem;
      box-shadow: 
        0 0 0 1px #2c2c2c,
        0 0 0 3px #e0e0e2,
        0 0 0 4px #808080;
    }
  }
`

export default style
