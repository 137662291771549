import style from './style'

const SubSections = (props) => {
  const renderSections = (subSections) => {
    const sections = subSections.map(section => {
      let subSection

      if(section.hasSubsections.length) subSection = renderSections(section.subSections)

      return (
        <div 
          id={`section${section._id}`}
          key={section._id}
          className='section' >
          <a 
            className='section__return'
            onClick={() => document.getElementById('sectionTOC').scrollIntoView({
              behavior: 'smooth',
              block: 'start'
            })}>
            ^
          </a>
          <h4 className='section__title'>{section.title}</h4>
          <div className='section__content' dangerouslySetInnerHTML={{__html: section.content}} />
          {subSection}
        </div>
      )
    })

    return sections
  }

  return props.currentPage.subSections && props.currentPage.subSections.length
    ? (<div className='sections'>{renderSections(props.currentPage.subSections)}<style jsx global>{style}</style></div>)
    : ''
}

export default SubSections
