import style from './style'
import Link from 'next/link'

const Nav = (props) => {
  const renderMenuItems = (children) => {
    const menuItems = children.map(child => {
      let subChildren

      if (child.children.length) subChildren = child.children.map(subChild => {
        return !subChild.draft 
          ? (
              <li
                className='nav__sub-item'
                key={subChild._id}>
                <Link 
                  href={subChild.isExternalLink ? subChild.externalLink : subChild.slug} 
                  as={subChild.isExternalLink ? subChild.externalLink : subChild.slug}>
                  <a 
                    className='nav__sub-link'
                    target={subChild.isExternalLink ? '_blank' : ''}>
                    {subChild.title}
                  </a>
                </Link>
              </li>
            )
          : ''
      })

      return !child.draft 
        ? (<li 
            className='nav__item'
            key={child._id}>
              <Link 
                href={child.isExternalLink ? child.externalLink : child.slug} 
                as={child.isExternalLink ? child.externalLink : child.slug}>
                <a 
                  className='nav__link'
                  target={child.isExternalLink ? '_blank' : ''}>
                  {child.title}
                </a>
              </Link>
              {subChildren 
                ? (<ul className='nav__sub'>{subChildren}</ul>) 
                : ''
              }
            </li>
          )
        : ''
    })

    return menuItems
  }

  return props.currentPage && props.currentPage.children && props.currentPage.children.length
    ? (<nav className='nav'>{renderMenuItems(props.currentPage.children)}<style jsx global>{style}</style></nav>)
    : ''
}

export default Nav
