import css from 'styled-jsx/css'

const style = css.global`
  .section-toc {
    &:not(.section-toc--sub) {
      margin-bottom: 3rem;
    }
  }

  .section-toc__item {
    margin-top: 0.5rem;
    cursor: pointer;
  }

  .section-toc__item .section-toc__item {
    padding-left: 0.75rem;
  }
`
export default style
