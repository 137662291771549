import style from './style'
import moment from 'moment'

const Footer = (props) => (
  <div className='footer'>
  <p>Copyright © 2004-{`${moment(new Date()).format('YYYY')}`} The Flow Chart Foundation. All rights reserved.</p>
    
    <p>FCF logo copyright © 1991, 2004 <a href='http://www.trevorwinkfield.com/'>Trevor Winkfield</a>. All rights reserved. FCF's logo is adapted from Trevor Winkfield's design for the cover of John Ashbery's book-length poem <em>Flow Chart</em> (Knopf, 1991). Used by permission of the artist, courtesy <a href='http://www.tibordenagy.com/'>Tibor de Nagy Gallery</a>, New York.</p>
    
    <p>Unless otherwise attributed, all material on this website was developed and prepared by Micaela Morrissette, Anni Rudegeair, Jenny Hendrix, and Rosangela Briscese, under the direction of David Kermani. Website designed by Anni Rudegeair. Images are used either by permission or in compliance with fair use restrictions. The information presented here is intended for non-profit scholarly and research purposes only, and should not be used without appropriate citation (see <a>Terms of Use</a>). The Flow Chart Foundation, the Ashbery Resource Center, Bard College, and employees of these organizations are not responsible for the accuracy of material contained in references or links to resources outside this site that may be cited here.</p>
    <style jsx global>{style}</style>
  </div>
)

export default Footer
