import css from 'styled-jsx/css'

const style = css.global`
  .page__title {
    color: #3f389b;
    font-weight: bold;
    margin-bottom: 1rem;
    font-size: 1.5rem;
  }
`

export default style
