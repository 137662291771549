import css from 'styled-jsx/css'

const style = css.global`
  .search-form {
    border: 10px solid #bbbbbb;
    padding: 1rem;
    display: flex;
    flex-wrap: wrap;
    justify-content: space-around;
    margin-bottom: 2rem;

    &:before {
      content: 'Please fill in the desired search phrases';
      width: 100%;
      margin-bottom: 1rem;
      display: block;
      text-align: center;
      font-weight: bold;
    }
  }

  .search-form__field-group {
    width: 100%;
    margin-bottom: 0.5rem;

    & .search-form__field-ordinal {
      display: inline-block;
      margin-right: 0.75rem;
      width: 1.5rem;
    }

    & .search-form__select {
      margin-right: 0.75rem;
    }

    & .search-form__input {
      float: right;
      min-width: 30ch;
		max-width: 200px;
    }

    &[rel='basic'] {
      display: flex;
      justify-content: center;
    }
  }
  
  select[name='searchType'] {
    display: none !important;
  }

  div[rel='basicSearch'] {
    width: 100%;
    text-align: center;
    margin-bottom: 1rem;
  }

  div[rel='basicSearch'] input {
    float: none!important;
    margin: 0 auto!important;
  }

  select[name='searchType'] {
    display: block;
    margin: 0 auto 1rem;    
  }

  select[name='searchPrecision'] {
    display: block;
    margin: 0 auto 1rem;
  }

  label[for='clearSubmit'] {
    width: 100%;
    text-align: center;

    & > input:first-child {
      margin-right: 1rem;
    }
  }

  button[rel='-'],
  button[rel='+'] {
    float: right;
  }

  select[name='perPage'] {
    margin-left: 0.5rem;
  }

  .search-results__page-control {
    display: flex;
    justify-content: space-between;
    margin-bottom: 2rem;
    flex-wrap: wrap;
  }

  .search-results__paginater {
    display: flex;
    width: 75%;
    justify-content: flex-end;
  }

  .search-results__paginater-title {
    margin-right: 1rem;
  }

  .search-results__buttons {
    margin-left: auto;
    margin-top: 1rem;
  }

  .search-results__button[rel='+'] {
    margin-left: 1rem;
  }

  .search-results__paginater-link {
    display: inline-block;
    margin-right: 0.5rem;
    font-weight: bold;

    & a {
      cursor: pointer;
    }
  }

  .search-results__paginater-total {
    display: inline-block;
    margin-right: 0.5rem;
    font-weight: bold;

    & a {
      cursor: pointer;
    }
  }

  .search-results {
    min-height: 300px;
    position: relative;
  }

  .search-result {
    position: relative;
    border: 5px solid #bbbbbb;
    padding: 1rem;
    margin-bottom: 1rem;
    transition: opacity 150ms ease-in-out;

    @nest .is-searching & {
      opacity: 0.375;
    }

    @nest .is-notSearching & {
      opacity: 1;
    }
  }

  .search-result__field {
    display: flex;
    justify-content: flex-start;
    margin-bottom: 0.5rem;
  }

  .search-result__field-key {
    position: relative;
    width: 30%;
    margin-right: 2rem;
    padding-left: 1rem;
  }

  .search-result__field-sort {
    display: block;
    position: absolute;
    left: -0.375rem;
    top: -0.25rem;
    width: 1.375rem;
    height: 1.375rem;
    stroke: #3f389a;
    cursor: pointer;
  }

  .search-result__field-value {
    width: 70%;
    line-height: 1.375;

    line-height: 1.5;

    & h2,
    & h3,
    & h4,
    & h5, 
    & h6  {
      display: block;
      margin-top: 2rem;
      font-weight: bold;
      margin-bottom: 1rem;
    }

    & strong {
      font-weight: bold
    }

    & em {
      font-style: italic;
    }

    & blockquote {
      margin-left: 2rem;
      margin-bottom: 2rem;
      margin-top: 2rem;
      max-width: 70ch;
      font-style: italic;
      padding-left: 1rem;
      border-left: 3px solid gray;
    }
  }
`

export default style
