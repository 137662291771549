import css from 'styled-jsx/css'

const style = css.global`
  .nav {
    display: flex;
    flex-direction: column;
    justify-content: flex-start;
    position: relative;
    float: left; 
    width: 36%;
    list-style: none;
    margin-top: 1rem;
    margin-right: 1rem;
    margin-bottom: 0.25rem;
  }


  .nav__link {
    display: block;
    width: 100%;
    font-size: 0.875rem;
    color: #666666;
    text-decoration: none;
    background-color: #fcd1ca;
    padding: 0.25rem;
    border: 1px solid black;
    margin-bottom: 0.375rem;
    line-height: 1.375;
    cursor: pointer;

    &:nth-of-type(n + 2) {
      display: none;
    }

    &:hover {
      color: #666666;
      background-color: #e0e0e0;
    }
  }

  .nav__item {
    position: relative;
  }

  .nav__sub {
    display: none;
    position: absolute;
    width: 100%;
    flex-direction: column;
    border: 1px solid black;
    left: 0;
    top: calc(100% - 0.375rem - 1px);
    z-index: 10;

    @media (min-width: 680px) {
      left: calc(100% - 1px);
      top: 0;
    }

    @nest .nav__item:hover & {
      display: flex;
    }
  }

  .nav__sub-item {
    &:last-child {
      margin-bottom: 0;
    }
  }

  .nav__sub-link {
    display: block;
    padding: 0.375rem;
    font-size: 0.875rem;
    color: #666666;
    text-decoration: none;
    background-color: #fcd1ca;
    cursor: pointer;

    &:hover {
      background-color: #e0e0e0;
      color: #666666;
    }
  }
`

export default style
