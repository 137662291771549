import css from 'styled-jsx/css'

const style = css.global`
  .content {
    margin-bottom: 1rem;
    line-height: 1.5;

    & h2,
    & h3,
    & h4,
    & h5, 
    & h6  {
      display: block;
      margin-top: 2rem;
      font-weight: bold;
      margin-bottom: 1rem;
    }

    & strong {
      font-weight: bold
    }

    & em {
      font-style: italic;
    }

    & blockquote {
      margin-left: 2rem;
      margin-bottom: 2rem;
      margin-top: 2rem;
      max-width: 70ch;
      font-style: italic;
      padding-left: 1rem;
      border-left: 3px solid gray;
    }

  }
  
  .sections ul {
    line-height: 1.5;
    display: block;
    list-style-type: disc;
    -webkit-margin-before: 1em;
    -webkit-margin-after: 1em;
    -webkit-margin-start: 0px;
    -webkit-margin-end: 0px;
    -webkit-padding-start: 40px;
  }
`

export default style
