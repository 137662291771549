import Head from 'next/head'
import Header from './../Header'
import Main from './../Main'
import SearchBar from './../SearchBar'
import Footer from './../Footer'
import style from './style'

export default class Layout extends React.Component {
  state = {
    isPageReady: false
  }

  componentDidMount() {
    this.getPages()
  }

  getPages = async () => {
    if(window.localStorage) {
      if(localStorage.getItem('pages')) {
        const storedPages = JSON.parse(localStorage.getItem('pages'))
        const milsPassed = new Date().getTime() - new Date(storedPages[0]).getTime()
        
        if(milsPassed > 90000) {
          let pagesReq = await fetch('https://data.ashberyresourcecenter.com/api/page/list')
          pagesReq = await pagesReq.json()
          
          pagesReq.unshift(new Date())
          localStorage.setItem('pages', JSON.stringify(pagesReq))
        } 
      } else {
        let pagesReq = await fetch('https://data.ashberyresourcecenter.com/api/page/list')
        pagesReq = await pagesReq.json()
        pagesReq.unshift(new Date())
        localStorage.setItem('pages', JSON.stringify(pagesReq))
      }

      this.setState(() => {
        let pages = JSON.parse(localStorage.getItem('pages')) 
        pages.shift()

        return { pages }
      }, () => this.filterPageToState())
    } else {
        let pagesReq = await fetch('https://data.ashberyresourcecenter.com/api/page/list')
        pagesReq = await pagesReq.json()

        this.setState(() => {
          return { pages: pagesReq }
        }, () => this.filterPageToState())
    } 
  }

  filterPageToState = () => {
    const path = this.props.url.query.slug ? this.props.url.query.slug : ''

    const currentPage = this.state.pages.filter((page) => {
      return path.length
        ? page.slug === path
        : page.slug === 'home'
    })

    currentPage.length
      ? this.setState(() => ({
          currentPage: currentPage[0]
        }), () => this.populateDescendentPostArrays(this.state.currentPage))
      : this.setState(() => ({currentPage: '404' }), () => this.setState({isPageReady: true}))
  }

  populateDescendentPostArrays = (parent) => {
    const collectDescendents = (parent, descType) => {
      const newDescendents = parent[descType].map((descendent) => {
        let newDescendent = this.state.pages.filter((page) => page._id === descendent)[0]
        
        if(newDescendent[descType].length) newDescendent[descType] = collectDescendents(newDescendent, descType)

        return newDescendent
      })

      return newDescendents
    }

    this.setState((prevState) => ({
      currentPage: Object.assign({}, prevState.currentPage, {
        children: collectDescendents(parent, 'children'),
        subSections: collectDescendents(parent, 'subSections')
      })
    }), () => this.setState({isPageReady: true}))
  }


  render() {
    const pageReadyClass = this.state.isPageReady ? 'is-ready' : 'is-notReady'
    const pageClass = this.state.currentPage && this.state.currentPage != '404' 
            ? (this.state.currentPage.slug === 'home' ? 'page--home' : 'page--default') 
            : (this.state.currentPage && this.state.currentPage === '404' ? 'page--default' : '')
    const childrenWithProps = React.Children.map(this.props.children, (child) => React.cloneElement(child, {
            currentPage: this.state.currentPage
          })) 

    return (
      <div className={`page ${pageClass} ${pageReadyClass}`}>
        <Head>
          <meta name="viewport" content="width=device-width, initial-scale=1" />
          <link href='/static/styles/app.css' rel='stylesheet' />
			<script src="/static/js/iframeResizer.contentWindow.min.js"></script>
        </Head>
        <div className='page__container'>
          <Header asPath={this.props.url.asPath} />
          <Main isPageReady={this.state.isPageReady}>
            {childrenWithProps}
          </Main>
        </div>
        <Footer />
        <style jsx global>{style}</style>
      </div>
    )
  }
}
