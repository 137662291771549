import css from 'styled-jsx/css'

const style = css.global`
  .footer {
    display: block;
    margin: 2rem auto;
    max-width: 680px;

    @media (max-width: 680px) {
      padding-left: 0.625rem;
      padding-right: 0.625rem;
    }

    & p {
      font-size: 0.875rem;
      line-height: 1.5;
      margin-bottom: 1rem;
    }
  }
`

export default style
