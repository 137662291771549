import fetch from 'isomorphic-unfetch'
import Layout from './../components/Layout'
import Nav from './../components/Nav'
import CatSearch from './../components/CatSearch'
import SubSections from './../components/SubSections'
import SubSectionTOC from './../components/SubSectionTOC'
import PageTitle from './../components/PageTitle'
import Content from './../components/Content'

export default class Page extends React.Component {
  render() {
    return (
      <Layout url={this.props.url}>
        <PageTitle />
        <Nav />
        <Content />
        <SubSectionTOC />
        <SubSections />
      </Layout>
    )
  }
}
