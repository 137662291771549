import css from 'styled-jsx/css'

const style = css.global`
  .page {
    opacity: 0;
    transition: opacity 150ms ease-in-out;
  }
    .page.is-notReady {
      opacity: 0;
    }
    .page.is-ready {
      opacity: 1;
    }
    
  .page__container p:not(:last-child) {
    margin-bottom: 1rem;
  }

  .page__container {
    max-width: 680px;
    margin: 2rem auto;

    @nest .page--home &  {
      display: flex;
      flex-direction: column;
      position: relative;
      background-color: #e0e0e0;
      padding: 10px 10px 20px 10px;
      box-shadow:
        0 0 0 1px #2c2c2c,
        0 0 0 3px #e0e0e2,
        0 0 0 4px #808080;
    }
  }
`

export default style
