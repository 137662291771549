import css from 'styled-jsx/css'

const style = css.global`
  .section {
    margin-bottom: 3rem;
    position: relative;
  }

  .section__return {
    position: absolute;
    top: 1rem;
    right: 1rem;
    font-weight: bold;
    cursor: pointer;
  }

  .section__title {
    font-size: 1rem;
    color: black;
    font-weight: bold;
    font-style: italic;
    margin-bottom: 1rem;
    padding-top: 1rem;
  }
`

export default style
