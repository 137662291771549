import style from './style'
import Link from 'next/link'

const Header = (props) => (
  <header className='header'>
    <h1 
      className='header__title'>
      <a href='https://ashberyresourcecenter.com' className='header__title'>
        {props.asPath === '/' && (
          <span className='header__title-welcome'>Welcome to the</span>
        )}
        <span className='header__title-arc'>Ashbery Resource Center</span>
        <span className='header__title-project'><em>a project of</em> The Flow Chart Foundation</span>
      </a>
    </h1>
    <div className='header__graphic'>
      <img  src='/static/images/winkfieldgraphic.png' />
    </div>
    <style jsx global>{style}</style>
  </header>
)

export default Header
